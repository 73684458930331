const API_HOST = window.location.protocol + '//' + window.location.host; 

// TODO -- Qui conviene separare host per parte API e host rtc
// TODO -- ricontrollare uso di API_BASENAME, così prende il localhost

const config = {
	APP_VERSION: '1.9.9',
	APP_BASENAME: '/app/',
	API_BASENAME: API_HOST + '/api',
	TIMEOUT_DELAY: 10
};

console.log( 'API HOST:', API_HOST );
console.log( 'Version:', config.APP_VERSION );

export default config