import React from "react";

import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import { 
	ProvideCore, 
	PrivateRoute 
} from "./Core";

import config from "./config";

import Page404 from "./pages/Page404";
import UserLogin from "./pages/UserLogin";
import UserDashboard from "./pages/UserDashboard";

export default function App() {
	
	return (
		<ProvideCore>
			<Router basename={ config.APP_BASENAME }>
				<Switch>
					<PrivateRoute exact path="/">
						<UserDashboard />
					</PrivateRoute>
					<Route exact path="/login">					
						<UserLogin />
					</Route>
					<Route>					
						<Page404 />
					</Route>
				</Switch>
			</Router>
		</ProvideCore>
	);
  
}