import React, { useState } from "react";

import {
	useHistory,
	Redirect
} from "react-router-dom";

import {
	useCore,
	apiRequest,
	isMobileDevice,
	initSocket
} from "../Core";

import Modals from "../components/Modals";
import Loader from "../components/Loader";

import config from "../config";

export default function UserLogin() {

	const { 
		enableNoSleep,
		setIsLoading,
		authUser, 
		setAuthUser, 
		setTeacher, 
		setCurrentError, 
		currentUserJoin 
	} = useCore();

	const [ activeSessions, setActiveSessions ] = useState( [] );
	const [ tempStudent, setTempStudent ] = useState( {} );

	let history = useHistory();

	/* */

	async function sendLogin( e ) {

		e.preventDefault();

		const formData = new FormData( document.querySelector('#frmLogin') );

		const params = {
			usr: formData.get('usr'),
			pwd: formData.get('pwd'),
			context: formData.get('context')
		};

		try {

			setIsLoading( true );

			const endpoint = config.API_BASENAME + '/login/';
			const apiData = await apiRequest( endpoint, 'POST', false, params );

			console.log('apiData:', apiData);

			if ( apiData.result === "error" ) {

				if ( apiData.code === 552 || apiData.code === 551 ) setCurrentError( apiData.message );
				else setCurrentError( 'I dati di accesso inseriti non risultano validi.' );

				setIsLoading( false );

			}
			else {

				let user = { ...apiData };

				user.name = formData.get('usr');
				user.cloud_id = apiData.cloudData.response.user_id;
				user.school_id = apiData.cloudData.response.school_id;
				
				setIsLoading( false );

				if ( formData.get('context') === "teacher" ) {

					initSocket( apiData.sessionData.server_url );

					setTeacher( true );

					user.isTeacher = true;
					user.session_id = apiData.sessionData.session_id;
					user.created_at = apiData.sessionData.created_at;
					user.rtcServer = apiData.sessionData.server_url;

					setAuthUser( user );

					currentUserJoin( user );

					history.push('/');

				}
				else {

					if ( apiData.sessionData.length ) {

						user.isTeacher = false;						

						setTempStudent( user );

						setActiveSessions( apiData.sessionData );

					}
					else {

						setCurrentError('ATTENZIONE! Non ci sono ancora sessioni disponibili, attendere e riprovare a collegarsi.');

					}

				}

			}

		} catch (err) {

			console.log('Error:', err);

			setCurrentError('Error: ' + err);

			setIsLoading( false );

		}

	};

	/* */

	async function connectSession( e, sessionID ) {

		e.preventDefault();

		const params = {
			session_id: sessionID,
			studente_id: tempStudent.cloud_id,
			studente_name: tempStudent.name
		};

		try {

			setIsLoading( true );

			const endpoint = config.API_BASENAME + '/join/';
			const apiData = await apiRequest(endpoint, 'POST', tempStudent.tokenDjango, params);

			console.log('apiData:', apiData);

			setIsLoading( false );

			if ( apiData.result === "error" ) {

				setCurrentError('Errore durante la connessione alla sessione selezionata: ' + apiData.message );

				history.push('/');

			}
			else {

				initSocket( apiData.data.server_url );			

				console.log( 'tempStudent', tempStudent );

				let user = { ...tempStudent };

				user.session_id = sessionID;
				user.rtcServer = apiData.data.server_url;

				setAuthUser( user );

				// random delay (da 10ms a 50ms) per evitare maggiormente la possibilità di ingressi contemporanei
				const rand_delay = config.TIMEOUT_DELAY + Math.random() * ( 50 - config.TIMEOUT_DELAY );

				setTimeout(() => { 

					console.log( 'currentUserJoin randomly delayed...', rand_delay );

					currentUserJoin( user );

					history.push('/');

				}, rand_delay );

			}

		} catch (err) {

			console.log('Error:', err);

			setCurrentError('Error: ' + err);

			setIsLoading( false );

		}

	}

	return (

		authUser ?

			<Redirect to="/" />

			:
			<>
				<Loader/>

				<main className="main main-login d-flex justify-content-center vh-100">

					<div className="intro-container w-100">
						<div className="logo-container">
							<div className="logo">
								<a href="login-docente.html">
									<img src="assets/images/logo_opedialingue.png" alt="Opedia Lingue" />
								</a>
							</div>
							<div className="login-deco">
								<img src="assets/images/login_cloud.png" alt="Opedia Lingue" />
							</div>
						</div>
					</div>

					{
						activeSessions.length ?

							<div className="form-container align-self-center p-4">
								<div className="form-logo d-flex align-items-center justify-content-center">
									<div className="form-heading text-center">
										<div className="form-deco">
											<img src="assets/images/pencil.png" alt="Opedia Lingue" />
										</div>
										<div className="form-title">
											<span>Seleziona Docente:</span>
										</div>
									</div>
								</div>
								<div className="teacher-list d-flex flex-column px-4 py-3">

									{
										activeSessions.map((item) => {

											return (

												<div key={item.docente_id} className="card card-teacher py-4" style={{ cursor: "pointer" }}>
													<div className="card-teacher-item" onClick={(e) => connectSession(e, item.session_id)}>
														<i className="fas fa-user"></i>
														<span className="ml-3">
															{ item.docente_name }<br/>
															<small>Sessione: { item.session_id } - <small>{ item.created_at }</small></small>
														</span>
													</div>
												</div>

											)

										})
									}

								</div>
							</div>

							:

							<div className="form-container align-self-center p-4">
								<div className="form-logo d-flex align-items-center justify-content-center">
									<div className="form-heading text-center">
										<div className="form-deco">
											<img src="assets/images/cloud.png" alt="Opedia Lingue" />
										</div>
										<div className="form-title">
											<span>Accedi con</span> <img src="assets/images/logo_opedia_cloud.png" alt="Opedia Lingue" />											
										</div>
									</div>
								</div>
								<div className="form text-center">
									<form id="frmLogin" onSubmit={(e) => sendLogin(e)}>
										<div className="form-group">
											<label>Accedi come:</label>
											<select className="custom-select" name="context" defaultValue="student">
												<option value="student">Studente</option>
												<option value="teacher">Docente</option>
											</select>
										</div>
										<div className="form-group">
											<label>Nome Utente:</label>
											<input name="usr" type="text" className="form-control" autocorrect="off" autocapitalize="off" required />
										</div>
										<div className="form-group">
											<label>Password:</label>
											<input name="pwd" type="password" className="form-control" autocorrect="off" autocapitalize="off" required />
										</div>
										<button type="submit" className="btn my-3" onClick={ () => isMobileDevice() ? enableNoSleep() : null }>LOGIN</button>
										<br/><br/>
										<small>v { config.APP_VERSION }</small>
									</form>
								</div>
							</div>

					}

				</main>

				<Modals />
			</>
	);

}
