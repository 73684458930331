
const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
};

const _EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen',
    stopScreen: 'stopScreen'
};

//const muteLabelBorderColor = '#3f3f3f';
//const talkLabelBorderColor = '#1DC020';

export default class RoomClient {

    constructor( remoteAudioEl, mediasoupClient, socket, room_id, name, is_teacher, opedia_id, session_id, successCallback, updateCallback, errorCallback ) {

        console.log( '*** RoomClient constructor', room_id, name, is_teacher, opedia_id, session_id );
        
        this.name = name;
        this.is_teacher = is_teacher;
        this.opedia_id = opedia_id;
        this.session_id = session_id;
        this.remoteAudioEl = remoteAudioEl;
        this.mediasoupClient = mediasoupClient;

        this.successCallback = successCallback;
        this.updateCallback = updateCallback;
        this.errorCallback = errorCallback;

        this.socket = socket;
        this.producerTransport = null;
        this.consumerTransport = null;
        this.consumerPlainTransport_id = null;
        this.device = null;
        this.room_id = room_id;

        this.consumers = new Map();
        this.producers = new Map();

        this.producer = null;

        /**
         * map that contains a mediatype as key and producer_id as value
         */
        this.producerLabel = new Map();

        this._isOpen = false;
        this.eventListeners = new Map();

        Object.keys( _EVENTS ).forEach( function( evt ){

            this.eventListeners.set( evt, [] );

        }.bind(this) );

        this.createRoom( room_id, session_id, is_teacher ).then( async function() {
            
            await this.join( session_id, room_id, this.name, this.is_teacher, this.opedia_id );
            this.initSockets();
            this._isOpen = true;
            
            //successCallback();

        }.bind(this) );

    }

    ////////// INIT /////////

    async createRoom( room_id, session_id, is_teacher ) {

        console.log('*** createRoom', room_id, session_id, is_teacher);

        await this.socket.request('createRoom', {
            room_id,
            session_id,
            is_teacher
        }).catch(err => {
            
            console.log(err);
            alert( '[RoomClient] Error createRoom: ' + err );

        })

    }

    async join( session_id, room_id, name, is_teacher, opedia_id ) {

        console.log( '***join', session_id, room_id, name, is_teacher, opedia_id );

        this.socket.request('join', {
            session_id,
            room_id,
            name,            
            is_teacher,
            opedia_id
        }).then(async function (e) {
            
            console.log( '***joined', e );

            const data = await this.socket.request('getRouterRtpCapabilities', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher
            });
            let device = await this.loadDevice(data);

            console.log( '*** device after join:', device );

            this.device = device;

            await this.initTransports( device, name );

            // Comunico i miei producers (audio, video ecc...) a tutti gli altri peers 
            this.socket.emit('getProducers', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher,
                name
            });

            // Controlla situazione peers
            setTimeout(() => {
                //const str_session_id = session_id;
                this.socket.request('getPeerData', {
                    socket_id: this.socket.id,
                    session_id: this.session_id,
                    room_id: this.room_id,
                    is_teacher: this.is_teacher
                }).then(async function(data) {
                    console.log('getPeerData:', data);                
                }).catch(err => {

                    console.error('Error getPeerData:', err);
                    alert( '[RoomClient] Error getPeerData: ' + err );

                });
            }, 1500);

        }.bind(this)).catch(e => {
            
            console.log(e);
            alert( '[RoomClient] Error join: ' + e );

        })
    }

    async loadDevice(routerRtpCapabilities) {
        let device;
        try {
            device = new this.mediasoupClient.Device();

            console.log('device', device);

        } catch (error) {

            if (error.name === 'UnsupportedError') {
                console.error('browser not supported');
            }
            console.error(error);
            alert( '[RoomClient] Error loadDevice: ' + error );

        }
        await device.load({
            routerRtpCapabilities
        });
        return device;
    }

    async initTransports( device, name ) {

        // init producerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher,
                forceTcp: false,
                rtpCapabilities: device.rtpCapabilities
            });
            if (data.error) {
                console.error(data.error);
                alert( '[RoomClient] Error init producerTransport' + data.error );
                return;
            }

            this.producerTransport = device.createSendTransport(data);

            this.producerTransport.on('connect', async function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        socket_id: this.socket.id,  
                        session_id: this.session_id,
                        room_id: this.room_id,
                        is_teacher: this.is_teacher,
                        dtlsParameters,
                        transport_id: data.id
                    })
                    .then(callback)
                    .catch(errback)
            }.bind(this));

            this.producerTransport.on('produce', async function ({
                kind,
                rtpParameters
            }, callback, errback) {
                try {

                    const {
                        producer_id
                    } = await this.socket.request('produce', {
                        socket_id: this.socket.id,
                        session_id: this.session_id,
                        room_id: this.room_id,
                        is_teacher: this.is_teacher,
                        producerTransportId: this.producerTransport.id,
                        kind,
                        rtpParameters,
                    });

                    console.log( "*** after socket 'produce':", name, producer_id );

                    callback({
                        id: producer_id
                    });

                } catch (err) {
                    errback(err);
                    alert( '[RoomClient] Error init producerTransport' + err );
                }
            }.bind(this));

            this.producerTransport.on('connectionstatechange', function (state) {
                switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        //localVideo.srcObject = stream
                        break;

                    case 'failed':
                        this.producerTransport.close();
                        break;

                    default:
                        break;
                }
            }.bind(this));
        }

        // init consumerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher,
                forceTcp: false,
                rtpCapabilities: device.rtpCapabilities
            });
            if (data.error) {
                console.error(data.error);
                alert( '[RoomClient] Error init consumerTransport' + data.error );
                return;
            }

            // only one needed
            this.consumerTransport = device.createRecvTransport(data);
            console.log( '*** this.consumerTransport', this.consumerTransport );
            
            this.consumerTransport.on('connect', function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        socket_id: this.socket.id,
                        session_id: this.session_id,
                        room_id: this.room_id,
                        is_teacher: this.is_teacher,
                        transport_id: this.consumerTransport.id,
                        dtlsParameters
                    })
                    .then(callback)
                    .catch(errback);
            }.bind(this));

            this.consumerTransport.on('connectionstatechange', async function (state) {
                switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        //remoteVideo.srcObject = await stream;
                        //await socket.request('resume');
                        break;

                    case 'failed':
                        this.consumerTransport.close();
                        break;

                    default:
                        break;
                }
            }.bind(this));

        }

    }

    initSockets() {

        this.socket.on('consumerClosed', function ({
            consumer_id,
            producer_id
        }) {
            console.log('closing consumer:', consumer_id);
            this.removeConsumer(consumer_id, producer_id);
        }.bind(this));

        /**
         * data: [ {
         *  producer_id:
         *  producer_socket_id:
         * }]
         */
        this.socket.on('newProducers', async function (data, req_name) {
            console.log('*** new producers', data, req_name);
            for (let {
                    producer_id,
                    producer_name,
                    is_teacher
                } of data) {
                await this.consume(producer_id, producer_name, is_teacher);
            }

            // fine operazioni di inizializzazione room
            if ( req_name === this.name ) this.successCallback();

        }.bind(this));

        this.socket.on('disconnect', function () {
            this.exit(true);
        }.bind(this));

        this.socket.on('consumerPaused', function (data) {
            console.log('*** consumerPaused', data);

            //this.updateCallback( 'pause', data );

        }.bind(this));

        this.socket.on('consumerResumed', function (data) {
            console.log('*** consumerResumed', data);

            //this.updateCallback( 'talk', data );

        }.bind(this));

        this.socket.on('consumerActive', function (data) {
            console.log('*** consumerActive', data);

            //this.updateCallback( 'active', data );

        }.bind(this));

        this.socket.on('consumerInactive', function (data) {
            console.log('*** consumerInactive', data);

            //this.updateCallback( 'inactive', data );

        }.bind(this));

        /*
        this.socket.on('broadcastMessage', function (data) {
            console.log('*** broadcastMessage', data);

            //let lbl = document.getElementById('label_' + data.producer_id);
            //lbl.style.borderColor = muteLabelBorderColor;

            this.updateCallback( 'message', data );

        }.bind(this));
        */

    }


    //////// MAIN FUNCTIONS /////////////


    async produce(type, deviceId = null) {
        let mediaConstraints = {};
        let audio = false;
        let screen = false;
        
        switch (type) {
            case mediaType.audio:
                mediaConstraints = {
                    // audio: {
                    //     deviceId: deviceId
                    // },
                    audio: true,
                    video: false
                };
                audio = true;
                break;
            case mediaType.video:
                mediaConstraints = {
                    audio: false,
                    video: {
                        width: {
                            min: 640,
                            ideal: 1920
                        },
                        height: {
                            min: 400,
                            ideal: 1080
                        },
                        deviceId: deviceId
                        /*aspectRatio: {
                            ideal: 1.7777777778
                        }*/
                    }
                };
                break;
            case mediaType.screen:
                mediaConstraints = false;
                screen = true;
                break;
            default:
                return;
                //break;
        }
        if (!this.device.canProduce('video') && !audio) {
            console.error('cannot produce video');
            return;
        }
        if (this.producerLabel.has(type)) {
            console.log('producer already exists for this type ' + type);            
            return;
        }
        console.log('mediacontraints:',mediaConstraints);

        let stream;
        
        try {

            stream = screen ? await navigator.mediaDevices.getDisplayMedia() : await navigator.mediaDevices.getUserMedia(mediaConstraints);
            console.log('getSupportedConstraints:', navigator.mediaDevices.getSupportedConstraints());

            const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];
            const params = {
                track
            };
            if (!audio && !screen) {
                params.encodings = [{
                        rid: 'r0',
                        maxBitrate: 100000,
                        //scaleResolutionDownBy: 10.0,
                        scalabilityMode: 'S1T3'
                    },
                    {
                        rid: 'r1',
                        maxBitrate: 300000,
                        scalabilityMode: 'S1T3'
                    },
                    {
                        rid: 'r2',
                        maxBitrate: 900000,
                        scalabilityMode: 'S1T3'
                    }
                ];
                params.codecOptions = {
                    videoGoogleStartBitrate: 1000
                };
            }

            console.log( '*** this.producerTransport', this.producerTransport );
            
            this.producer = await this.producerTransport.produce(params);

            console.log('producer', this.producer);
            console.log('params', params);

            this.producers.set( this.producer.id, this.producer );

           this.producer.on('trackended', () => {
                this.closeProducer(type);
            });

            this.producer.on('transportclose', () => {
                console.log('producer transport close');
                this.producers.delete( this.producer.id );
            });

            this.producer.on('close', () => {
                console.log('closing producer');
                this.producers.delete( this.producer.id );
            });

            this.producerLabel.set(type, this.producer.id);

            switch (type) {
                case mediaType.audio:
                    this.event(_EVENTS.startAudio);
                    break;
                case mediaType.video:
                    this.event(_EVENTS.startVideo);
                    break;
                case mediaType.screen:
                    this.event(_EVENTS.startScreen);
                    break;
                default:
                    return;
                    //break;
            }

        } catch (err) {

            if ( err.name === 'NotAllowedError' ) {

                alert( 'Attenzione! Per continuare correttamente è necessario consentire il permesso di utilizzare il microfono.' );
    
            }

            console.error("Produce Error -> ", err);

            this.errorCallback( err );

        }

    }

    async consume(producer_id, producer_name, is_teacher) {

        console.log( '**** consume', producer_id, producer_name, is_teacher, this.room_id );

        try {

            this.getConsumeStream(producer_id).then(function ({
                consumer,
                stream,
                kind
            }) {

                console.log( '*** getConsumeStream', consumer.id, consumer );
    
                this.consumers.set(consumer.id, consumer);
    
                let elem;
                if (kind === 'video') {
                    /*
                    elem = document.createElement('video');
                    elem.srcObject = stream;
                    elem.id = consumer.id;
                    elem.playsinline = false;
                    elem.autoplay = true;
                    elem.className = "vid";
                    this.remoteVideoEl.appendChild(elem);
                    */
                } else {
    
                    console.log('*** New Consumer', consumer, this.room_id);
    
                    elem = document.createElement('audio');
                    elem.srcObject = stream;
                    elem.id = 'audio_' + consumer.id;
                    elem.playsinline = false;
                    elem.autoplay = true;
                    this.remoteAudioEl.appendChild( elem );
                    
                    const data = {
                        producer_id: producer_id,
                        producer_name: producer_name,
                        is_teacher: is_teacher
                    };
    
                    this.updateCallback( 'add', data, this.room_id );
    
                }
    
                consumer.on('trackended', function () {
                    this.removeConsumer(consumer.id);
                }.bind(this));
    
                consumer.on('transportclose', function () {
                    this.removeConsumer(consumer.id);
                }.bind(this));
    
            }.bind(this));
            
        } catch (err) {

            console.error("Consume Error -> ", err);

            this.errorCallback( err );

        }

        
    }

    async getConsumeStream(producerId) {

        try {

            // Rimane in attesa dei parametri necessari negli step successivi
            const max_wait = 10;  // Attesa massima
            let tentativi = 0;

            while ( tentativi < max_wait ) {

                tentativi++;

                if ( this.consumerTransport && this.device ) tentativi = max_wait;

                await this.sleep( 200 );

            }

            const {
                rtpCapabilities
            } = this.device;

            console.log( '*** consume params:', this.session_id, this.room_id, this.is_teacher, rtpCapabilities, this.consumerTransport.id, producerId, this.socket.id );

            const data = await this.socket.request('consume', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher,
                rtpCapabilities,
                consumerTransportId: this.consumerTransport.id, // might be 
                producerId
            });

            console.log( '*** data after request consume:', data );
    
            // TODO - verificare perché in alcuni casi kind arriva undefined
            let {
                id,
                kind,
                rtpParameters,
            } = data;
            console.log('data2:', data);
    
            let codecOptions = {};
    
            const consumer = await this.consumerTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions,
            });
            const stream = new MediaStream();
            stream.addTrack(consumer.track);
            return {
                consumer,
                stream,
                kind
            };

        } catch (err) {

            console.error("getConsumeStream Error -> ", err);

            this.errorCallback( err );

        }

    }

    closeProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type);
            return;
        }
        let producer_id = this.producerLabel.get(type)
        console.log(producer_id);
        this.socket.emit('producerClosed', {
            socket_id: this.socket.id,
            session_id: this.session_id,
            room_id: this.room_id,
            is_teacher: this.is_teacher,
            producer_id
        });
        this.producers.get(producer_id).close();
        this.producers.delete(producer_id);
        this.producerLabel.delete(type);

        if (type !== mediaType.audio) {
            let elem = document.getElementById(producer_id);
            elem.srcObject.getTracks().forEach(function (track) {
                track.stop();
            });
            elem.parentNode.removeChild(elem);
        }

        switch (type) {
            case mediaType.audio:
                this.event(_EVENTS.stopAudio);
                break;
            case mediaType.video:
                this.event(_EVENTS.stopVideo);
                break;
            case mediaType.screen:
                this.event(_EVENTS.stopScreen);
                break;
            default:
                return;
                //break;
        }

    }

    pauseProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type);
            return;
        }
        let producer_id = this.producerLabel.get(type);

        if ( this.producers.get(producer_id) ) {
            this.producers.get(producer_id).pause();
            this.socket.emit('producerPaused', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher,
                producer_id
            });
        }
    }

    resumeProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type);
            return;
        }
        let producer_id = this.producerLabel.get(type);
        
        if ( this.producers.get(producer_id) ) {
            this.producers.get(producer_id).resume();
            this.socket.emit('producerResumed', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher,
                producer_id
            });
        }
    }

    activeProducer(type) {
        
        console.log('*** send activeProducer');

        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type);
            return;
        }
        let producer_id = this.producerLabel.get(type);
        this.socket.emit('producerActive', {
            socket_id: this.socket.id,
            session_id: this.session_id,
            room_id: this.room_id,
            is_teacher: this.is_teacher,
            producer_id
        });
    }

    inactiveProducer(type) {

        console.log('*** send inactiveProducer');

        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type);
            return;
        }
        let producer_id = this.producerLabel.get(type);
        this.socket.emit('producerInactive', {
            socket_id: this.socket.id,
            session_id: this.session_id,
            room_id: this.room_id,
            is_teacher: this.is_teacher,
            producer_id
        });
    }

    removeConsumer(consumer_id, producer_id) {
        let elem = document.getElementById('audio_' + consumer_id);

        if ( elem ) {
            elem.srcObject.getTracks().forEach(function (track) {
                track.stop();
            });
            elem.parentNode.removeChild(elem);
        }

        this.consumers.delete(consumer_id);

        //TODO Callback rimozione elementi

        // //rimuovo LABEL utente
        // document.getElementById('label_' + producer_id).remove();
        //
        // updateStudenti(this.consumers.size);

        const data = {
            producer_id: producer_id
        };

        this.updateCallback( 'delete', data, this.room_id );

    }

    exit(offline = false, callback) {

        console.log( 'RoomClient exit', offline );

        let clean = function () {
            console.log( '*** clean start' );
            this._isOpen = false;
            this.consumerTransport.close();
            this.producerTransport.close();
            this.socket.removeAllListeners();
            console.log( '*** clean end' );
        }.bind(this);

        if (!offline) {
            this.socket.request('exitRoom', {
                socket_id: this.socket.id,
                session_id: this.session_id,
                room_id: this.room_id,
                is_teacher: this.is_teacher
            }).then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
                clean();
                callback();
            }.bind(this));
        } else {
            clean();
        }

        this.event(_EVENTS.exitRoom);

    }

    ///////  HELPERS //////////

    async roomInfo() {
        let info = await this.socket.request('getMyRoomInfo', {
            socket_id: this.socket.id,
            session_id: this.session_id,
            room_id: this.room_id,
            is_teacher: this.is_teacher
        });
        return info;
    }

    static get mediaType() {
        return mediaType;
    }

    event(evt) {
        if (this.eventListeners.has(evt)) {
            this.eventListeners.get(evt).forEach(callback => callback());
        }
    }

    on(evt, callback) {
        this.eventListeners.get(evt).push(callback);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //////// GETTERS ////////

    isOpen() {
        return this._isOpen;
    }

    static get EVENTS() {
        return _EVENTS;
    }
}